<template>
  <div class="switchCity">
    <div class="content">
      <div class="position">
        <div class="text">当前定位</div>
        <div class="bottom flex02">
          <div class="bl flex01">
            <img src="../../assets/colorP.svg" alt="">
            <span class="city">{{cityName}}</span>
          </div>
          <!-- <div class="br" @click="reposition">
            重新定位
          </div> -->
        </div>
      </div>
      <div class="cityList">
        <van-tree-select
        :items="provionList"
        height="200vw"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-nav="provide"
        @click-item="cityActive"
      />
      </div>
    </div>
  </div>
</template>

<script>
import {regionList } from "@/api/studentProduct"
import { setLocal, getLocal } from '@/utils/local.js'
export default {
  data () {
    return {
      activeIndex:0 , // 父级
      activeId: 0, // 子集
      cityName:'',
      provionList:[],
      cityList:[]
    }
  },
  created () {
    this.getregionList()
    this.cityName = getLocal('cityName')
  },
  methods:{
    // 
    async getregionList() {
      const res = await regionList()
      if(res.data.code == 200) {
        this.provionList = res.data.data
        this.provionList.map(item=>{
          item.text = item.name
        })
        this.provionList[0]['className'] = 'my-class'
        this.bgprovion(this.provionList[0].id,0)
      }
    },
    // 
    reposition () {

    },
    // 默认省
    async bgprovion(id,num) {
      const res = await regionList({parent_id:id})
      if(res.data.code == 200) {
        this.cityList = res.data.data
        this.cityList.map(item=>{
          item.text = item.name
        })
        this.provionList[num]['className'] = 'my-class'
        this.provionList[num]['children'] = this.cityList
        this.$forceUpdate()
      }
    },
    provide(val) {
      this.bgprovion(this.provionList[val].id,val) 
      
    },
    cityActive(data) {
      this.valueName = data.text
      setLocal('cityName',data.text)
      this.$toast({
        message:'切换成功',
        type:'success'
      })
      setTimeout(() => {
        this.$router.push({path:'/homePage',query:{value:data.text}})
      }, 500);
    }
  }
}
</script>

<style lang="less" scoped>
.switchCity {
  background: #f7f8f9;
  // height: calc(100vh - 145px);
  height: 100%;
  font-size: 14px;
  .content {
    
    .position {
      padding: 10px 15px 10px 25px;
      background-color: #fff;
        font-size: 16px;
      .text {
        color: #999999;
      }
      .bottom {
        margin-top: 15px;
        .bl {
          color: #333333;
          font-weight: 600;
          .city {
            padding-left: 5px;
          }
        }
        .br {
          color: #2163E9;
        }
      }
    }
    .cityList {
      padding: 10px 0 ;
      margin-top: 10px;
      background-color: #fff;
    }
  }
  .van-sidebar-item--select::before {
    background-color: #2163E9;
  }
  .van-tree-select__item--active {
    color: #2163E9;
  }
  .flex01 {
    display: flex;
    align-items: center;
  }
  .flex02 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .van-sidebar-item--select[data-v-04aa44f9]::before {
    margin-left: 10px;
  }
  .van-tree-select__nav-item {
    padding-left: 20px;
  }
  .van-sidebar {
    width: 200px;
  }
  .van-tree-select__content {
    flex: 1.5;
    -webkit-box-direction: 1.5;
  }
}
</style>
